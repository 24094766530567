<template>
  <NoteSection
    label="Encounter Details"
  >
    <radio-buttons
      v-model="visit_location"
      :items="visitLocations"
      label="Visit location"
      class="col-sm-6"
      required
    ></radio-buttons>
    <show-value
      v-if="visitType"
      :value="visit_type_label"
      label="Visit type"
      rem:color="visitType === 'New' ? 'success' : 'error'"
      class="col-sm-6"
    ></show-value>
    <select-box
      :value="place_of_service_id"
      :items="facilities"
      label="Facility"
      clearable="false"
      class="col-sm-6"
      required
      @change="facilityChanged"
    ></select-box>
    <date-picker
      v-model="visit_date"
      :min="dos_default_min"
      :max="dos_default_max"
      rules="lastYear"
      label="Visit Date"
      class="col-sm-6"
      date-input
      required
      @keyup="dateChanged($event)"
      @input="lastDOSCheck"
    ></date-picker>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    visitLocation: {
      type: String,
      default: null,
    },
    visitType: {
      type: String,
      default: null,
    },
    placeOfServiceId: {
      type: Number,
      default: null,
    },
    visitDate: {
      type: String,
      default: null,
    },
    encounter: {
      type: Object,
      default: () => {},
    },
    patientId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      index: 0,
      visit_location: this.visitLocation,
      visit_type_label: `${this.visitType} Visit`,
      place_of_service_id: this.placeOfServiceId,
      visit_date: this.visitDate,
      dos_confirm: null,
      dos_prev_ack: 0,
      dos_default_min: this.$date().subtract(1, 'year').format('YYYY-MM-DD'),
      dos_default_max: this.$date().format('YYYY-MM-DD'),
    }
  },
  computed: {
    ...mapGetters('auth', ['facilities']),
    ...mapGetters('encounters', ['visitLocations']),
    dos_range() {
      const prevEncs = this.$store.getters['encounters/previousSignedUnsignedEncounters'](this.encounter.patient_id, this.encounter.practice_type_id, this.encounter.id, 365)

      let count = 0
      let min = null
      let max = null
      while (count < prevEncs.length) {
        if ( prevEncs[count].visit_date > this.encounter.visit_date ) {
          max = prevEncs[count].visit_date
        }
        else if ( prevEncs[count].visit_date < this.encounter.visit_date ) {
          // Because encounters/previousSignedUnsignedEncounters returns a sorted array,
          // the first instance where the visit date is earlier than this encounter's visit date should be the min date range
          min = prevEncs[count].visit_date
          count = prevEncs.length
        }
        count++
      }
      return {
          min: min || this.dos_default_min,
          max: max || this.dos_default_max
        }
      },
  },
  watch: {
    visit_location() {
      this.$emit('update:visit-location', this.visit_location)
    },
    place_of_service_id() {
      this.$emit('update:place-of-service-id', this.place_of_service_id)
    },
    visit_date() {
      this.$nextTick(() => {
        if ((this.visit_date <= this.dos_range.min || this.visit_date >= this.dos_range.max) && this.visit_date !== this.encounter.visit_date) {
          this.$root.confirm({
            html: true,
            titleIconColor: 'error',
            title: 'Invalid visit date!',
            body: this.generateVisitDateMsg(),
            cancel: false,
          }).then(() => {
            this.visit_date = this.encounter.visit_date
          })
        } else if (this.encounter.is_quick_entry
            && !this.$custom.isObjectEmpty(this.encounter.communication_log)
            && this.encounter.communication_log.visit_date === this.encounter.visit_date
            && this.visit_date !== this.encounter.visit_date) {
          this.$root.confirm({
            titleIconColor: 'warning',
            cancel: 'No',
            confirm: 'Change visit date',
            title: 'Notice!',
            body: 'This encounter has an associated communication log.  '
              + 'Changing the visit date will no longer allow you to resend the log.  '
              + 'Change the visit date anyway?',
          }).then(result => {
            if (result) {
              this.$emit('update:visit-date', this.visit_date)
            } else {
              this.visit_date = this.encounter.visit_date
            }
          })
        } else {
          this.$emit('update:visit-date', this.visit_date)
        }
      })
    },
    visitDate() {
      this.visit_date = this.visitDate
    },
    visitLocation(newValue) {
      this.visit_location = newValue
    },
  },

  mounted() {
    if (this.$root.previousPath) {
      this.dos_prev_ack = 1
    }

    // const prevEncs = this.$store.getters['encounters/previousSignedUnsignedEncounters'](this.encounter.patient_id, this.encounter.practice_type_id, this.encounter.id, 365)
    //
    // let count = 0
    // let minFound = false
    // while (count < prevEncs.length || !minFound) {
    //   if ( prevEncs[count].visit_date > this.visit_date ) {
    //     this.dos_range.max = prevEncs[count].visit_date
    //   }
    //   else if ( prevEncs[count].visit_date < this.visit_date ) {
    //     // Because encounters/previousSignedUnsignedEncounters returns a sorted array,
    //     // the first instance where the visit date is earlier than this encounter's visit date should be the min date range
    //     this.dos_range.min = prevEncs[count].visit_date
    //     minFound = true
    //   }
    //   count++s
    // }
    // this.generateVisitDateMsg()
  },
  methods: {
    formatDate(dateTime) {
      return this.$date(dateTime).format('M/D/YYYY')
    },
    confirmVisitDate(prevDate) {
      // Add 7 days to previous encounter note and if current encounter visit date is 7 days > than previous date trigger return true
      const currDate = this.visit_date
      let visitDate = prevDate
      visitDate = new Date(visitDate)
      visitDate.setDate(visitDate.getDate() + 7)
      const [preVisitDate] = visitDate.toISOString().split('T')

      return preVisitDate < currDate
    },

    generateVisitDateMsg() {
      let baseString = ' Please enter a date '
      let chronologicalString = 'Encounters must occur in chronological order. <br>'

      // String when there are no notes for this patient from the last 3 years
      if (this.dos_range.min === this.dos_default_min && this.dos_range.max === this.dos_default_max) {
        baseString += 'between today and one year previously.'
        // String when a previous note has been found and only min is modified
      } else if (this.dos_range.min !== this.dos_default_min && this.dos_range.max === this.dos_default_max) {
        baseString = chronologicalString + baseString
        baseString += `after the previous note: ${this.$date(this.dos_range.min).format('M/D/YYYY')}`
        // String when a following note has been found and only max is modified
      } else if (this.dos_range.min === this.dos_default_min && this.dos_range.max !== this.dos_default_max) {
        baseString = chronologicalString + baseString
        baseString += `before the following note: ${this.$date(this.dos_range.max).format('M/D/YYYY')}`
        // String when both a previous and following note has been found
      } else {
        baseString = chronologicalString + baseString
        baseString += 'between the following notes. <br>'
          + `<br> Previous note: ${this.$date(this.dos_range.min).format('M/D/YYYY')}`
          + `<br> Following note: ${this.$date(this.dos_range.max).format('M/D/YYYY')}`
      }
      return baseString
    },

    lastDOSCheck() {
      // method that will send updated dos for recent hospitalization component
      this.$root.$emit('dateChange', this.visit_date)

      // Ensure confirm doesn't trigger on load or New visit type
      if (this.dos_confirm === null || this.dos_prev_ack !== 0 || this.visitType === 'New') {
        this.dos_confirm = 1

        return
      }

      // this.encounter.visit_date and this.visitDate are rendered values, this.visit_date is the updated value with change in date picker.
      // This prevents the confirm dialogue from showing when there is no change.
      if (this.encounter.visit_date !== this.visit_date && this.dos_confirm !== null && this.dos_confirm !== 2) {
        // Encounter getter only looks back one year inform user if previous visit date is more than 7 days before current dos
        // getter that receives most recent encounter whether signed or unsigned
        const previousSignedUnSignedEncounter = this.$store.getters['encounters/previousSignedUnsignedEncounters'](this.encounter.patient_id, this.encounter.practice_type_id, this.encounter.id, this.encounter.visit_date, 365)[0]
        if (previousSignedUnSignedEncounter) {
          if (this.confirmVisitDate(previousSignedUnSignedEncounter.visit_date)) {
            const noteDialog = `The entered date of service is over a week beyond the patient's previous visit date. Please double check that there is not another visit to enter between ${this.formatDate(previousSignedUnSignedEncounter.visit_date)} and ${this.formatDate(this.visit_date)}.`
            this.$root.confirm({
              title: 'Notice: Date of Service',
              body: noteDialog,
              large: true,
              cancel: false,
            })
          }

          // Ensures dialogue doesn't appear twice for user
          this.dos_confirm = 2
        }
      }
    },

    dateChanged(event) {
      if (this.$custom.isDateValid(event.target.value)) {
        this.visit_date = this.$date(event.target.value).format('YYYY-MM-DD')
      }
    },
    facilityChanged(newFacility) {
      this.$root.confirm({
        html: true,
        cancel: 'No',
        confirm: 'Yes',
        title: 'Are you sure you wish to change facility?',
        body: `Patient was last seen at facility
          <b>${this.$store.getters['facilities/getById'](this.place_of_service_id)?.title}</b>,
          are you sure you want to change facility to
          <b>${this.$store.getters['facilities/getById'](newFacility)?.title}</b>?`,
      }).then(result => {
        if (result) {
          this.place_of_service_id = newFacility
          this.$store.commit('patients/updateFacilityAcquiredWound', {
            id: this.patientId,
            facility: newFacility,
          })
        } else {
          const currentFacility = this.place_of_service_id
          this.place_of_service_id = null
          this.$nextTick(() => {
            this.place_of_service_id = currentFacility
          })
        }
      })
    },
  },
}
</script>
